import axios from 'axios';
import { useState, useEffect } from 'react';
import Spinner from '../layout/Spinner';
import fallbackImage from '../../assets/fallbackImage.png';
import imgCache from '../imgCache';

const Image = ({ image: { farm, server, id, secret, title, owner } }) => {
	const [user, setUser] = useState(null);
	const apiKey = process.env.REACT_APP_API_KEY;
	const photoUrl = `https://farm${farm}.staticflickr.com/${server}/${id}_${secret}.jpg`;

	useEffect(() => {
		const cancelToken = axios.CancelToken.source();
		const getUser = async () => {
			try {
				const { data } = await axios.get(
					`https://api.flickr.com/services/rest/?method=flickr.people.getInfo&api_key=${apiKey}&user_id=${owner}&format=json&nojsoncallback=1`,
					{ cancelToken: cancelToken.token }
				);
				setUser(data.person);
			} catch (error) {
				// no user data returned, set username to 'Unknown User'
				setUser({ profileurl: { _content: '' }, username: { _content: 'Unknown User' } });

				// response status code outside 2xx
				if (error.response) {
					console.log(error.response.data, error.response.status);

					// no response received
				} else if (error.request) {
					console.log(error.request);

					// any other errors
				} else {
					console.log('Error', error.message);
				}
			}
		};
		getUser();
		return () => {
			cancelToken.cancel('axios request cancelled');
		};
	}, [apiKey, owner]);

	// imgCache.read(photoUrl);

	return (
		<article>
			<img
				className='flickrImg'
				src={photoUrl}
				onError={(e) => {
					e.target.onerror = null;
					console.log('Image not found');
					e.target.src = fallbackImage;
				}}
				alt={title}
			/>
			<a className='title' href={photoUrl} target='_blank' rel='noreferrer'>
				{title}
			</a>
			{user ? (
				<a className='author' href={user.profileurl._content} target='_blank' rel='noreferrer'>
					by {user.username._content}
				</a>
			) : (
				<Spinner className='author-spinner' />
			)}
		</article>
	);
};

export default Image;
