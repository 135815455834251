import InfiniteScroll from 'react-infinite-scroll-component';
import Image from '../images/Image';
import Spinner from '../layout/Spinner';

const Home = ({ images, moreImages, hasMore, loading }) => {
	return (
		<main>
			{loading ? (
				<Spinner className='main-spinner' />
			) : (
				<InfiniteScroll
					dataLength={images.length}
					next={moreImages}
					hasMore={hasMore}
					style={{ height: 'none', overflow: 'none' }}
					className='photos'
					loader={<Spinner className='main-spinner' />}>
					{images.map((image) => (
						<Image key={image.id} image={image} />
					))}
				</InfiniteScroll>
			)}
		</main>
	);
};

export default Home;
