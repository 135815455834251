import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Search from '../images/Search';

const Navbar = ({ handleChange, handleSubmit }) => {
	return (
		<nav className='navbar'>
			<Link to='/' className='nav-title'>
				Flickr Searchr
			</Link>
			<ul className='menu'>
				<li className='nav-item'>
					<NavLink exact to='/'>
						Home
					</NavLink>
				</li>
				{/* <li className='nav-item'>
					<NavLink to='/about'>About</NavLink>
				</li> */}
				<li className='search-item'>
					<Search handleChange={handleChange} handleSubmit={handleSubmit} />
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
