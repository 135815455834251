const Search = ({ handleChange, handleSubmit }) => {
	return (
		<div>
			<form onSubmit={handleSubmit} className='form'>
				<input type='text' placeholder='Search Photos...' onChange={handleChange} />
				<input type='submit' className='btn-search' />
			</form>
		</div>
	);
};

export default Search;
