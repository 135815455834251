import { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../src/components/layout/Navbar';
import Home from '../src/components/pages/Home';
// import About from '../src/components/pages/About';
import './sass/main.scss';

const App = () => {
	const [query, setQuery] = useState('');
	const [images, setImages] = useState([]);
	const [pagination, setPagination] = useState({ page: 0, pages: 0 });
	const [loading, setLoading] = useState(false);

	const apiKey = process.env.REACT_APP_API_KEY;

	const handleSubmit = (evt) => {
		evt.preventDefault();
		setImages([]);
		setPagination({ page: 0, pages: 0 });
		initialImages();
	};

	const handleChange = (evt) => {
		setQuery(evt.target.value);
	};

	const initialImages = async () => {
		setLoading(true);
		const {
			data: { photos }
		} = await axios.get(
			`https://api.flickr.com/services/rest/?method=flickr.photos.search&api_key=${apiKey}&text=${query}&per_page=150&page=1&safe_search=1&format=json&nojsoncallback=1`
		);
		setLoading(false);
		setPagination({ page: 1, pages: photos.pages });
		setImages(photos.photo);
	};

	const moreImages = async () => {
		const {
			data: { photos }
		} = await axios.get(
			`https://api.flickr.com/services/rest/?method=flickr.photos.search&api_key=${apiKey}&text=${query}&per_page=15&page=${
				pagination.page + 1
			}&safe_search=1&format=json&nojsoncallback=1`
		);
		setPagination({ ...pagination, page: photos.page });
		setImages((images) => [...images, ...photos.photo]);
	};

	return (
		<Router>
			<Navbar handleChange={handleChange} handleSubmit={handleSubmit} />
			<Switch>
				<Route
					exact
					path='/'
					render={(props) => (
						<Home
							images={images}
							moreImages={moreImages}
							hasMore={pagination.page < pagination.pages}
							loading={loading}
						/>
					)}
				/>
				{/* <Route exact path='/about' component={About} /> */}
			</Switch>
		</Router>
	);
};

export default App;
